<template>
    <div class="store_index">
        <van-nav-bar title="取奶网点" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="search_box">
            <van-search v-model="searchValue" label="网点" placeholder="请输入搜索关键词" show-action @search="onSearch"
                @cancel="onCancel" @clear="onCancel">
                <template #action>
                    <div @click="onSearch">搜索</div>
                </template>
            </van-search>
        </div>
        <div class="list_box">
            <div class="left">
                <van-sidebar v-model="activeKey" @change="areaChange">
                    <van-sidebar-item :title="item.title" v-for="(item, index) in storeAreaData" :key="index" />
                </van-sidebar>
            </div>
            <div class="right">
                <div class="store" v-if="storeListData.length">
                    <van-collapse accordion v-model="activeName" bind:change="onChange">
                        <van-collapse-item :title="item.title" :name="index" :key="index"
                            v-for="(item, index) in storeListData" icon="shop">
                            <div class="store_box">
                                <div class="cover" v-if="item.image.length" style="text-align: center;">
                                    <van-image width="10rem" height="10rem" fit="contain" lazy-load :src="item.image" />
                                </div>
                                <div class="address">
                                    <van-icon name="location-o" />网点地址：{{ item.address }}
                                </div>
                                <div class="btn">
                                    <van-button type="danger" block size="small"
                                        @click="onSelectStore(item)">我要选择此网点作为取货网点</van-button>
                                </div>
                            </div>
                        </van-collapse-item>
                    </van-collapse>
                </div>
                <van-empty description="没有检索到网点" v-else />
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onBeforeMount } from 'vue';
import request from '../../utils/request';
import { showConfirmDialog } from 'vant';
export default {
    setup() {
        const onClickLeft = () => history.back();
        const searchValue = ref('');
        const onSearch = () => {
            storeListData.value.length=0
            loadStoreData()
        };
        const onCancel = () => {
            searchValue.value=''
            loadStoreData()
        };
        const activeKey = ref(0);
        const areaChange = (index) => {
            activeKey.value = index
            storeListData.value.length=0
            loadStoreData()
        };
        const storeAreaData = ref([]);
        const storeListData = ref([]);
        const onSelectStore = (item) => {
            var selectedStoreInfo = {
                id:item.id,
                title:item.title
            }

            showConfirmDialog({
                title:'温馨提示',
                message:'您选择【'+item.title+'】成为您的取奶网点，是否确认？',
            }).then(()=>{
                localStorage.setItem('orderStore',JSON.stringify(selectedStoreInfo))
                history.back()
            })
        };
        const loadStoreData = () => {
            var areaTemp = storeAreaData.value
            var params = {
                title: searchValue.value,
                area_id: areaTemp[activeKey.value].id,
            };
            request({
                url: '/app/store',
                method: 'GET',
                params: params,
            }).then((res) => {
                if (res && res.items && res.items.length) {
                    storeListData.value = res.items
                }
            })
        };
        const loadStoreAreaData = () => {
            request({
                url: '/app/system_app',
                method: 'GET',
            }).then((res) => {
                if (res && res.app_data) {
                    storeAreaData.value = res.app_data.area_data

                    loadStoreData()
                }
            })
        };
        onBeforeMount(() => {
            loadStoreAreaData()
        });
        const activeName = ref(0);
        return {
            onClickLeft,
            searchValue,
            onSearch,
            onCancel,
            activeKey,
            areaChange,
            storeAreaData,
            storeListData,
            onSelectStore,
            activeName,
        }
    },
}
</script>
<style>
.store_index {
    overflow: hidden;
}

.store .store_box {}

.store .store_box .address {
    margin-bottom: 15rpx;
}

.store .store_box .btn {}

.store_index .list_box {
    display: flex;
}

.store_index .left {}

.store_index .right {
    flex: 1;
}</style>